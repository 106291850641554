<template>
  <div class="login-container">
    <div class="login-container-inner">
      <img src="@/assets/wifi-block-logo.svg" />
      <form action="/api/login" method="post">
        <div>
          <label>Username:</label>
          <input type="text" name="username" autofocus /><br />
        </div>
        <div>
          <label>Password:</label>
          <input type="password" name="password" />
        </div>
        <div>
          <input type="submit" value="Submit" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
};
</script>

<style lang="scss" scoped>
.login-container {
  background-color: $tuatara;
  height: 100%;
  color: $tuatara;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .login-container-inner {
    background-color: white;
    padding: 40px;
    border-radius: 20px;

    img {
      height: 50px;
      margin-bottom: 50px;
    }

    input[type='submit'] {
      margin: 40px auto 0 auto;
    }
  }
}
</style>
